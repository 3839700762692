<template>
  <div id="bg">
    <div class="container text-center">
      <div class="logo">
        <div class="text-center" style="width: 100%">
          <h2>
            X
          </h2>
          <span class="toefl-r">&reg;</span>
          <h2>Mocks</h2>
        </div>
      </div>
      <hr />
      <div class="mb-4">
        {{ rights }}
      </div>
      <div class="">
        <!-- Taiwan phone -->
        <!-- <el-tooltip
          class="item"
          :content="
            isoCodeIsCn ? '173-2124-7147' : '(02)2370-5397 | 0978-038-334'
          "
          placement="top"
          popper-class="tooltipColorFooter"
        >
          <el-button circle size="mini">
            <span class="icon">
              <i class="fa fa-phone"></i>
            </span>
          </el-button>
        </el-tooltip> -->

        <!-- Email -->
        <el-tooltip
          class="item"
          placement="top"
          popper-class="tooltipColorFooter"
        >
          <div slot="content">
            xmocks@gmail.com
          </div>
          <el-button circle size="mini" class="iconButton">
            <span class="icon">
              <i class="fa fa-envelope"></i>
            </span>
          </el-button>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  computed: {
    isoCodeIsCn() {
      return this.$store.getters["ipGeo/getGeoAfterCall"].iso_code === "CN";
    }
  },
  data() {
    return {
      rights: ""
    };
  },
  mounted() {
    // get current year
    const year = moment().year();
    this.rights = "© 2009-" + year + " X® Mocks. All rights reserved.";
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0;
}
.logo {
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo h2 {
  font-weight: 700;
  display: inline-block;
  font-size: 28px;
  margin: 0;
}
.container hr {
  border-top: 1px solid white;
}
.logo a {
  color: white !important;
}
.toefl-r {
  font-weight: 700;
  font-size: 16px;
  display: inline-block;
  vertical-align: top;
  // padding-top: 10px;
  margin-right: 5px;
}
.other-link {
  display: flex;
  justify-content:center;
}
.ielts {
  display: flex;
  color: #ff770c!important;
  margin: 0 10px;
  text-decoration: none;
}

.TestAdmit {
  text-decoration: none;
  color: #42a16a !important;
  margin: 0 10px;
}
.ielts .toefl-r {
  font-size: 12px;
}
.ielts h2,
.TestAdmit h2
 {
  font-size: 20px;
}

testAdmit {

}
#bg {
  color: white !important;
  background-color: var(--themeColor);
  padding: 1.3em 0 1.3em 0;
}
.icon {
  font-size: 16px;
  color: var(--themeColor);
}
.block1 {
  color: white;
  text-align: center;
  text-justify: center;
}
.rights {
  line-height: 32px;
}
.block2 {
  display: flex;
  justify-content: flex-end;
}

.iconButton {
  width: 36px;
  height: 36px;
}

@media screen and (max-width: 768px) {
  .block2 {
    // padding: 0px 120px 0px 120px;
    justify-content: center;
    padding-top: 10px;
    display: flex;
  }
  .btnsLeft {
    padding-right: 10px;
  }
}

@media screen and (max-width: 400px) {
  .block2 {
    display: block;
    text-align: center !important;
  }
  .btnsLeft {
    padding-right: 0px;
    padding-bottom: 8px;
  }
}

.input-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  background-size: cover;
  background-color: var(--themeColor);
}

.icon-email {
  mask: url("../assets/svg/email.svg") no-repeat center;
}

.icon-weibo {
  mask: url("../assets/svg/sina-weibo.svg") no-repeat center;
}

.icon-wechat {
  mask: url("../assets/svg/wechat.svg") no-repeat center;
}

.icon-qq {
  mask: url("../assets/svg/qqchat.svg") no-repeat center;
}

.icon-line {
  mask: url("../assets/svg/line-brands.svg") no-repeat center;
}

.icon-facebook {
  mask: url("../assets/svg/facebook.svg") no-repeat center;
}

.icon-mobile {
  mask: url("../assets/svg/cellphone-iphone.svg") no-repeat center;
}
</style>
